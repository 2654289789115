@use "../default/_variables" as v;
@use "../default/_mixins" as m;

/* Styles the h3 */
.mc__title {
    max-width: 235px;
    text-align: center;
    color: v.$secondary2; // Use existing variable
    margin: 25px auto 35px;

    @include m.smMinBreakPoint {
        margin: 0 auto 30px;
    }
}

/* Positions the form in the middle of the modal and ensures the content doesn't jump as status messages get added/removed */
.mc__form-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px; // Add padding for better spacing
    background-color: v.$primaryDark3; // Use existing variable
    border-radius: 6px; // Add border radius
    transition: height 0.2s ease;
}

/* Seperates the input fields vertically */
.mc__field-container {
    display: grid;
    grid-row-gap: 20px; // Adjust gap for better spacing
}

/* Provides styles for status messages */
.mc__alert {
    text-align: center;
    @include m.poppinsFontStack;

    &--sending {
        color: v.$highlight2;
        -webkit-animation: fade-loop 0.8s ease-in-out infinite alternate-reverse
            both;
        animation: fade-loop 0.8s ease-in-out infinite alternate-reverse both;
    }

    &--success {
        color: v.$secondary2;
        margin-top: -60px;
    }

    &--error {
        color: v.$warning;
        font-weight: bold;

        a {
            color: v.$highlight1;
            transition: 0.3s ease-in-out;
            font-weight: normal;
            display: block;
            margin-top: 5px;
            opacity: 0.7;

            &:hover {
                text-decoration: none;
                opacity: 1;
            }
        }
    }
}

/* Animation keyframes */
@-webkit-keyframes fade-loop {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-loop {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Additional button styling */
.mc__form-container .subscribe-button {
    background-color: v.$primaryDark3; // Use existing variable
    color: v.$secondary2; // Use existing variable
    padding: 10px 20px; // Adjust padding
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: v.$primaryDark2; // Use existing variable
    }
}
