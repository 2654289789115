// Screen sizes
$screen-sm: 676px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1300px;
$screen-full: 1400px;

// Colors
$primary: #d4d4d4; // Adjusted to theme color
$primaryDark1: #d4d4d4; // Adjusted to another theme color
$primaryDark2: #d4d4d4; // Adjusted to another theme color
$primaryDark3: #d4d4d4; // Darker version of a theme color
$secondary1: #d4d4d4; // Adjusted to border color for lighter shade
$secondary2: #1f1f25; // Adjusted to heading color
$highlight1: #ef0963; // Adjusted to another theme color
$highlight2: lighten(#f61b10, 20%); // Lighter version of theme color
$warning: #f61b10; // Adjusted to theme warning color

//===== Colors =====//
$theme-color: #f9004d;
$theme-color-2: #f61b10;
$theme-color-3: #ef0963;
$body-color: #1d1d24;
$heading-color: #1f1f25;
$border-color: #d4d4d4;
$white: #fff;

/*===============================
    Font Family 
=================================*/
$body-font: "Poppins", sans-serif;
$heading-font: "Poppins", sans-serif;

// Heading Font List
$font-1: "Poppins", sans-serif;

$fontList: $font-1;

/*===============================
    Color Variation 
=================================*/
$color-1: $theme-color;
$color-2: #f4769a;
$color-3: #2f21b3;
$color-4: #8956e2;
$colorList: $color-1, $color-2, $color-3, $color-4;

//===== Line Height =====//
$body-line-height: 1.714286;
//==== Font size =====//
$body-font-size: 14px;
$h1: 64px;
$h2: 54px;
$h3: 24px;
$h4: 20px;
$h5: 18px;
$h6: 16px;

//===== Social Colors =====//
$facebook: #3b5998;
$twitter: #00acee;
$google-plus: #dd4b39;
$pinterest: #c8232c;
$linkedin: #0e76a8;
$flickr: #ff0084;
$google: #dd4b39;
$youtube: #cd201f;

// Layouts
$smlg-device: "only screen and (max-width: 1199px)";

$extra-device: "only screen and (min-width: 1600px) and (max-width: 1919px)";

$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm-layout: "only screen and (max-width: 767px)";
$large-mobile: "only screen and (max-width: 575px)";
/* container 450px*/
$small-mobile: "only screen and (max-width: 479px)";
/* container 300px*/

@media #{$large-mobile} {
    .container {
        width: 450px;
    }
}

@media #{$small-mobile} {
    .container {
        width: 320px;
    }
}
